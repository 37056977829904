<template>
  <div class="homecss">
    <el-carousel class="carouselimg">
      <el-carousel-item v-for="item in 4" :key="item">
        <el-image :src="img" :fit="fit" />
      </el-carousel-item>
    </el-carousel>
    <div class="Aboutus">
      <div class="w1200">
        <div class="indexleft">
          <p>
            {{ $t(`Aboutus.about1`) }}
          </p>
          <p>
            <!-- {{ $t(`Aboutus.about2`) }} -->
          </p>
          <!-- <p>
            {{ $t(`Aboutus.about3`) }}
          </p> -->
        </div>
        <div class="indexright">
          <el-image
            class="imgw145"
            :src="autoimgyn == 'en' ? autoimgen : autoimg"
          />
        </div>
      </div>
    </div>
    <div class="WeGame">
      <div class="po">
        <el-image
          class="imgw145"
          :src="autoimgyn == 'en' ? gameimgen : gameimg"
        />

        <el-carousel class="carouselimg2">
          <el-carousel-item v-for="item in 5" :key="item">
            <div class="c2-img">
              <el-image
                :src="require(`@/assets/banner/${item}.jpg`)"
                :fit="fit"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="Contactus-Index">
      <div class="w1400">
        <div class="Contactus-Index_left pr">
          <div class="Contactus-Index_left_img"></div>
          <div class="pa Contactus-Index_left_cont">
            <div>
              <el-image :src="require('@/assets/nav.png')" />
              <span style="color: rgb(255 133 0); margin-left: 10px">{{
                $t(`contactus.title`)
              }}</span>
            </div>
            <div style="margin-top: 10px; font-size: 20px">
              <el-image :src="require('@/assets/nav2.png')" />
              <span style="margin-left: 10px">{{
                $t(`contactus.address`)
              }}</span>
            </div>
          </div>

          <div class="Contactus-Index_bottem">
            <ul>
              <li>
                <p>
                  <el-image
                    style="height: 20px; margin-right: 10px"
                    :src="require('@/assets/Emai.png')"
                  />
                  <b>Email</b>
                  <samp>hongyu@hongyuinfo.com</samp>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="Contactus-Index_rigth">
          <el-image
            class="imgw"
            :src="autoimgyn == 'en' ? usindexen : usindex"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: `home`,
  setup() {
    const img = require('@/assets/topimg.jpg')
    const autoimg = require('@/assets/autoindex.png')
    const autoimgen = require('@/assets/en/autoindex.png')
    const gameimg = require('@/assets/gameindex.png')
    const gameimgen = require('@/assets/en/gameindex.png')
    const usindex = require('@/assets/usindex.png')
    const usindexen = require('@/assets/en/usindex.png')
    const autoimgyn = sessionStorage.getItem('lang')
    const li_img = ''
    const router = useRouter()

    const goDetail = (type) => {
      if (type.length < 1) {
        return
      }
      router.push(`/Gameinside?id=${type}`)
    }
    return {
      img,
      li_img,
      autoimg,
      autoimgen,
      gameimg,
      gameimgen,
      usindex,
      usindexen,
      autoimgyn,
      goDetail,
    }
  },
}
</script>

<style scoped>
.c2-img {
  padding: 12px;
  background: orange;
  border-radius: 5px;
}
</style>
